.button {
	$this: &;
	display: inline-block;
	position: relative;
	color: $color-white;
	font-size: 16px;
	line-height: 36px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 17px 35px;
	letter-spacing: 0.3px;

	&__text {
		display: inline-block;
		vertical-align: middle;
		font-size: 16px; }

	&__icon {
		width: 36px;
		height: 36px;
		margin-left: 35px;
		display: inline-block;
		vertical-align: middle;
		position: relative;

		.icon {
			width: 36px;
			height: 36px;
			fill: $color-white; }

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 68px;
			height: 68px;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-image: $gradient-base;
			z-index: -2;
			animation: pulse 2s infinite linear; }

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 50px;
			height: 50px;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			background-image: $gradient-base;
			z-index: -1;
			transition: transform 0.25s; } }


	&_highlight {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.4;
			background-image: $gradient-base;
			z-index: -1;
			transition: opacity 0.25s; }

		&:hover {
			&::before {
				opacity: 1; } } }


	&_icon {
		font-size: 0;
		padding-left: 25px;
		padding-right: 20px;
		&::before {
			content: '';
			position: absolute;
			top: 10px;
			bottom: 10px;
			left: 0;
			width: 5px;
			background-color: $color-blue;
			z-index: -1;
			transition: width 0.25s; }

		&:hover {
			&::before {
				width: 100%; }

			#{$this}__icon {
				&::after {
					transform: translate(-50%, -50%) scale(0); }

				&::before {
					opacity: 0;
					animation: none; } } } }

	&_resp {
		&:hover {
			@include md {
				&#{$this}_icon {
					&::before {
						width: calc(100% + 5px); } } } }

		@include md {
			padding: 10px 35px;
			line-height: 14px;

			&#{$this}_icon {
				padding-left: 10px;
				padding-right: 0;

				&::before {
					width: 1px;
					top: 0;
					bottom: 0; }

				#{$this}__text {
					font-size: 12px; }

				#{$this}__icon {
					width: 14px;
					height: 14px;
					margin-left: 16px;

					&::before {
						width: 30px;
						height: 30px; }
					&::after {
						width: 20px;
						height: 20px; }

					.icon {
						width: 14px;
						height: 14px; } } } } }
	&_round {
		padding: 11px 19px 11px 11px;
		border-radius: 29px;
		background: linear-gradient(94.33deg, rgba(54, 108, 213, 0.2) 3.5%, rgba(86, 54, 213, 0.2) 100%);
		#{$this}__icon {
			position: relative;
			margin-left: 0;
			margin-right: 13px;
			.icon {
				@include vcenter;
				width: 24px;
				height: 20px; } }
		#{$this}__text {
			font-size: 14px;
			font-weight: 500;
			@include sm {
				font-size: 13px; } } } }


@keyframes pulse {
	0% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0.4; }
	50% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 0.4; }
	90% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 0; }
	100% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0.4; } }
