.section-formats {
	overflow: hidden;
	position: relative;
	background-color: $color-background;
	padding-top: 25px;
	padding-bottom: 90px;
	@include md {
		padding-bottom: 212px; }

	@include xs {
		padding-bottom: 225px;

		&::before {
			content: '';
			position: absolute;
			bottom: 80px;
			left: -480px;
			width: 1000px;
			height: 1000px;
			background-image: $gradient-circle; } }

	&__title {
		line-height: 1.3;
		margin-bottom: 90px;

		@include xs {
			margin-bottom: 70px; }

		br {
			@include md {
				display: none; }
			@include xs {
				display: block; } } }

	&__bg {
		position: absolute;
		left: 45%;
		top: 0;
		width: 55%;
		height: 100%;
		z-index: 0;
		display: flex;
		overflow: hidden;
		@include md {
			left: -10%;
			width: 110%;
			height: 660px;
			top: auto;
			bottom: 0;

			&::before {
				content: '';
				position: absolute;
				top: 45%;
				left: 50%;
				width: 100000px;
				height: 100000px;
				transform-origin: 0 0;
				transform: rotate(-30deg) translate(-50%, -100%);
				background: $color-background; } }


		@include xs {
			height: 520px;
			&::before {
				display: none; } }


		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: left bottom;
			@include xs {
				object-position: right top; } } }

	&__bg-img {
		width: 100%;
		height: 100%;
		background-position: left bottom;
		background-size: cover;
		background-repeat: no-repeat;
		// background-attachment: fixed
		@include xs {
			background-position: right top; } }

	&__inner {
		position: relative;
		z-index: 1; } }
