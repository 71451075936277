.section-mail {
  padding: 32px 0;
  background: linear-gradient(261.89deg, rgba(25, 22, 34, 0.61) 4.83%, rgba(25, 22, 34, 0) 49.33%), url(4258063.jpg);
  @include r(1199) {
    overflow: hidden; }
  @include md {
    padding: 20px 0 58px; }
  @include sm {
    padding: 30px 0 10px; }
  &__inner {
    position: relative; }
  &__content {
    position: relative;
    z-index: 2;
    @include sm {
      margin-bottom: 10px; } }
  &__title {
    margin-bottom: 48px;
    @include md {
      margin-bottom: 32px; } }
  &__text {
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 500;
    color: rgba($color-white, 0.7);
    @include md {
      margin-bottom: 48px; } }
  &__btns {
    font-size: 0;
    div {
      display: inline-block;
      vertical-align: top;
      @include md {
        display: block; }
      &:not(:last-child) {
        margin-right: 16px;
        @include md {
          margin-right: 0;
          margin-bottom: 15px; } } } }
  &__img {
    position: absolute;
    z-index: 1;
    top: 43%;
    // left: 60%
    right: 0;
    width: 47%;
    // width: 612px
    max-width: 612px;
    transform: translate(0, -50%);
    @include r(1439) {
      right: -50px; }
    @include r(1199) {
      max-width: 400px;
      right: auto;
      left: 70%; }
    @include md {
      max-width: 100%;
      width: 428px;
      left: 57%; }
    @include sm {
      position: static;
      width: 108%;
      max-width: 108%;
      transform: none; } } }
