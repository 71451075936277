.header {
	position: absolute;
	top: 40px;
	left: 0;
	width: 100%;
	z-index: 10;
	@include xs {
		top: 15px; }

	&__logo {
		width: 170px;
		flex-shrink: 0;

		img {
			width: 100%; }

		@include xs {
			width: 140px;
			margin-right: 10px; }
		@include r(360) {
			width: 110px;
			margin-right: 0; } }

	&__inner {
		display: flex;
		justify-content: space-between;
		@include md {
			margin-right: -8px; }
		@include xs {
			margin-right: 8px; } }

	&__left {
		display: flex;
		width: 100%; }

	&__menu {
		display: flex;
		width: 100%;
		position: relative;
		@include md {
			width: auto; }
		@include xs {
			top: 3px; } }

	&__menu-inner {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		top: -8px;
		transition: opacity 0.4s, transform 0.4s;
		&.is-hidden {
			@include md {
				pointer-events: none;
				opacity: 0;
				transform: translate(0, 10px); } }
		@include md {
			top: calc(100% + 17px);
			right: -25px;
			width: 206px;
			position: absolute;
			flex-direction: column;
			align-items: flex-start;
			background-color: $color-black;
			border-radius: 10px;

			&::before {
				content: '';
				position: absolute;
				top: -8px;
				right: 20px;
				width: 38px;
				height: 38px;
				background-color: $color-black;
				border-radius: 6px;
				transform: rotate(45deg);
				z-index: -1; } }
		@include xs {
			top: calc(100% + 25px); }
		@include r(360) {
			width: 170px; } }

	&__menu-link {
		color: $color-white;
		transition: color 0.25s;

		&:hover {
			color: $color-red; }

		@include md {
			display: block;
			padding: 10px 18px;
			width: 100%; }
		&:not(:last-child) {
			margin-right: 80px;
			@include r(1200) {
				margin-right: 5%; }
			@include md {
				margin-right: 0; } }


		&:first-child {
			@include md {
				padding-top: 20px; } }

		&:last-child {
			@include md {
				padding-bottom: 20px; } } }

	&__burger {
		display: none;

		@include md {
			display: block; } }



	&__controls {
		flex-shrink: 0;
		font-size: 0;

		.button {
			vertical-align: middle;
			&:not(:last-child) {
				margin-right: 16px; } } }

	&__enter-btn {
		@include xs {
			display: none; } } }
