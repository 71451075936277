.section-about {
	padding-top: 95px;
	padding-bottom: 125px;
	overflow: hidden;
	position: relative;

	@include md {
		padding-top: 55px;
		padding-bottom: 65px; }
	@include xs {
		padding-top: 30px;
		padding-bottom: 45px; }

	&::before {
		content: '';
		position: absolute;
		left: -486px;
		top: -423px;
		width: 958px;
		height: 958px;
		background-image: $gradient-circle;
		@include md {
			display: none; } }
	&__title {
		margin-bottom: 85px;
		@include md {
			margin-bottom: 80px; }
		@include xs {
			margin-bottom: 50px; } }

	&__inner {
		position: relative;
		z-index: 1; } }


