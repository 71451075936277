.footer {
	padding-top: 45px;
	padding-bottom: 39px;
	background-color: $color-background;

	&__inner {
		position: relative;
		text-align: center; }

	&__copy {
		font-size: 14px; }

	&__top-btn {
		position: absolute;
		bottom: -10px;
		right: 4px;
		padding-bottom: 12px;

		.icon {
			fill: $color-white;
			height: 25px;
			width: 15px;
			position: relative;
			left: 4px;
			animation: footer-arrow 1.5s infinite linear; }

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: $color-white; } } }

@keyframes footer-arrow {
	0% {
		transform: translate(0, -10px); }
	50% {
		transform: translate(0, 0); }
	100% {
		transform: translate(0, -10px); } }
