.hero {
	position: relative;
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 0;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover'; } }

	&__bg-img {
		width: 100%;
		height: 100%;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed; }

	&__container {
		height: 100%; }

	&__inner {
		min-height: 100vh;
		min-height: calc(var(--vh, 1vh) * 100);
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 75px;
		padding-top: 150px;
		transition: opacity 0.5s;
		@include md {
			padding-top: 90px;
			padding-bottom: 56px; }
		@include xs {
			padding-top: 60px; }

		&.is-hidden {
			opacity: 0; } }

	&__title {
		padding-right: 70px;
		margin-bottom: 40px;
		@include md {
			padding-right: 0;
			margin-bottom: 25px; }
		@include xs {
			margin-bottom: 40px; } }

	&__btn-wrap {
		padding-left: 25px;
		@include md {
			padding-left: 0; } }

	&__scroll-down {
		position: absolute;
		bottom: 70px;
		right: 37px;
		z-index: 1;
		@include md {
			display: none; } } }
