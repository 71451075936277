.formats {
	display: flex;
	max-width: 56%;
	justify-content: space-between;
	@include md {
		max-width: 100%;
		padding-left: 15px; }
	@include xs {
		padding-left: 0;
		flex-direction: column;
		align-items: center; }

	&__item {
		flex-shrink: 0;

		&:not(:last-child) {
			margin-bottom: 55px; } } }

