.icon-adult {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-circle-right {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-down {
  width: 0.06em;
  height: 1em;
  fill: initial;
}
.icon-arrow-up {
  width: 0.32em;
  height: 1em;
  fill: initial;
}
.icon-banner {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-burger {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-file {
  width: 1.2em;
  height: 1em;
  fill: initial;
}
.icon-fun {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-log-in {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-mail {
  width: 1.41em;
  height: 1em;
  fill: #fff;
}
.icon-news {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-pen {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-sport {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1.2em;
  height: 1em;
  fill: #fff;
}
.icon-youtube {
  width: 1.41em;
  height: 1em;
  fill: initial;
}
