.section-advants {
	position: relative;
	padding-top: 105px;
	overflow: hidden;

	@include md {
		padding-top: 16px; }

	@include xs {
		padding-top: 35px; }

	&::before {
		content: '';
		position: absolute;
		width: 1000px;
		height: 1000px;
		left: -295px;
		top: 150px;
		background-image: $gradient-circle;

		@include md {
			display: none; } }

	&__title {
		line-height: 1.1;
		margin-bottom: 83px;

		@include md {
			line-height: 1.4;
			margin-bottom: 63px; }
		@include xs {
			line-height: 1.3;
			margin-bottom: 75px; } } }
