.advants {
	$this: &;
	display: flex;
	flex-wrap: wrap;

	&__item {
		position: relative;
		width: calc(50% - 8px);
		border-top: 2px solid $color-grey;
		padding-top: 35px;
		padding-bottom: 35px;
		min-height: 345px;

		@include md {
			min-height: 245px; }
		@include xs {
			border-width: 1px;
			width: 100%;
			min-height: 225px; }

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 35px;
			height: 130px;
			width: 4px;
			background-color: $color-red;

			@include md {
				height: 86px; } }

		&:nth-child(odd) {
			margin-right: 16px;
			@include xs {
				margin-right: 0; } }

		&:hover {
			#{$this}__item-img {
				opacity: 1; } } }

	&__item-title {
		position: relative;
		padding-left: 30px;
		max-width: 410px;
		font-size: 56px;
		font-weight: 900;
		z-index: 1;
		cursor: default;

		@include md {
			padding-left: 10px;
			font-size: 32px;
			max-width: 240px; }

		@include xs {
			padding-left: 20px; } }



	&__item-img {
		opacity: 0;
		position: absolute;
		right: 45px;
		top: -45px;
		width: 280px;
		transition: opacity 1s;

		@include md {
			opacity: 1;
			width: 140px;
			right: 15px;
			top: -5px; }

		img {
			max-width: 100%; } } }


