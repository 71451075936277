$color-white: #fff;
$color-black: #191622;
$color-black-light: #2D283E;
$color-background: #201D2A;
$color-background-dark: #1A1622;
$color-grey: #BAB9BD;
$color-red: #EB5757;
$color-blue: #376AD5;
$gradient-base: linear-gradient(94.33deg, #366CD5 3.5%, #5636D5 100%);
$gradient-circle: radial-gradient(50% 50% at 50% 50%, #463D62 0%, rgba(37, 32, 52, 0) 100%);
$gradient-circle-after: radial-gradient(50% 50% at 50% 50%, #463D62 0%, rgba(37, 32, 52, 0) 100%);
