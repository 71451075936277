.format {
	$this: &;
	position: relative;
	&__icon {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 162px;
		height: 162px;
		margin-bottom: 60px;
		border-radius: 50%;
		.icon {
			fill: $color-white; } }

	&__icon-circle {
		position: absolute;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: $color-red;
		border: 5px solid $color-black-light; }
	&__title {
		position: relative;
		font-size: 32px;

		text-align: center;

		@include xs {
			left: 0; } }

	&_video {

		#{$this}__icon {
			border: 3px solid $color-blue;

			&::before {
				content: '';
				position: absolute;
				width: 126px;
				height: 126px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				position: absolute;
				border-radius: 50%;
				border: 3px solid rgba($color-blue, 0.4);
				z-index: -1; } }

		#{$this}__icon-circle {
			top: 0;
			right: -10px; }

		#{$this}__title {
			left: -12px; }

		.icon {
			width: 96px;
			height: 68px; } }

	&_banner {
		#{$this}__icon {
			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				position: absolute;
				background-image: url(../img/banner-layer-1.svg);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				z-index: -2; }

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 122px;
				height: 122px;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				background-image: url(../img/banner-layer-2.svg);
				z-index: -1; } }

		#{$this}__icon-circle {
			left: 57px;
			bottom: -32px; }

		.icon {
			width: 70px;
			height: 70px;
			border-radius: 20px; } }

	&_branding {
		#{$this}__icon-circle {
			left: -7px;
			bottom: 6px; }

		#{$this}__icon {
			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				position: absolute;
				background-image: $gradient-base;
				border-radius: 50%;
				z-index: -2; }

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100px;
				height: 100px;
				border-radius: 50%;
				background-color: $color-background;
				clip: rect(0, 50px, 100px, 0);
				z-index: -1; } }
		&::after {
			content: '';
			position: absolute;
			top: 31px;
			left: 50%;
			transform: translate(-50%, 0);
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background-color: $color-background;
			clip: rect(50px, 100px, 100px, 50px);
			z-index: -1; }
		.icon {
			width: 83px;
			height: 83px; } } }


