.about-categories {
	$this: &;
	&__item {
		position: relative;
		padding-bottom: 60px;
		border-bottom: 1px solid $color-grey;
		display: flex;
		align-items: flex-end;
		@include md {
			padding-bottom: 110px; }
		@include xs {
			padding-bottom: 45px; }

		&:not(:first-child) {
			min-height: 235px;
			@include xs {
				min-height: 117px; } }

		&.is-active {
			#{$this}__item-img {
				opacity: 1;
				transform: scale(1) translate(0, 0); } } }

	&__item-text {
		display: flex;
		align-items: center;
		font-size: 0;
		position: relative;
		z-index: 1;
		.icon {
			width: 48px;
			height: 48px;
			fill: $color-grey;
			margin-right: 25px;
			flex-shrink: 0;
			@include xs {
				width: 24px;
				height: 24px;
				margin-left: 8px;
				margin-right: 13px; } }
		span {
			font-size: 32px;
			color: $color-grey;
			@include xs {
				font-size: 16px; } }

		@include md {
			align-items: flex-start;
			max-width: 350px; }
		@include xs {
			max-width: 190px; } }

	&__item-img {
		position: absolute;
		top: -105px;
		right: 50px;
		width: 450px;
		height: 450px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		transform: scale(0) translate(50%, 50%);
		transition: opacity 0.5s, transform 0.5s;
		@include md {
			top: -70px;
			right: 30px;
			width: 260px;
			height: 260px; }
		@include xs {
			top: -60px;
			right: -10px;
			width: 200px;
			height: 200px; }

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 590px;
			height: 590px;
			border-radius: 50%;
			background-image: $gradient-circle;
			// border: 1px solid rgba($color-blue, 0.2)
			z-index: -1;
			@include md {
				width: 360px;
				height: 360px; }
			@include xs {
				width: 300px;
				height: 300px; } }

		&::after {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 1000px;
			height: 1000px;
			background-image: $gradient-circle-after;
			z-index: -2;
			@include md {
				display: none; } }

		img {
			max-width: 90%;
			max-height: 90%; } } }
