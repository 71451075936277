.out {
	position: relative; }

body {
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.2;
	color: $color-white;
	background-color: $color-background-dark; }

.container {
	max-width: 1440px;
	padding-left: 80px;
	padding-right: 80px;
	margin-left: auto;
	margin-right: auto;
	@include md {
		padding-left: 40px;
		padding-right: 40px; }
	@include xs {
		padding-left: 16px;
		padding-right: 16px; } }


.burger {
	display: inline-block;
	width: 32px;
	height: 32px;
	padding: 4px;
	border-radius: 5px;
	background-color: rgba($color-white, 0.2);

	.icon {
		width: 24px;
		height: 24px;
		fill: $color-white; } }

.title {
	font-size: 72px;
	line-height: 1.2;
	font-weight: 900;
	@include md {
		font-size: 48px;
		line-height: 1.4; }
	@include xs {
		font-size: 36px;
		line-height: 1.2; }

	strong {
		@include stroke(2, $color-red);
		color: $color-black;
		@include md {
			@include stroke(1, $color-red); }
		@supports (-webkit-text-stroke-width: 2px) {
			text-shadow: none;
			-webkit-text-fill-color: transparent;
			-webkit-text-stroke-color: $color-red;
			-webkit-text-stroke-width: 2px;
			@include md {
				-webkit-text-stroke-width: 1px; } } } }

.scroll-down-btn {
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	color: $color-white;
	padding-top: 152px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 1px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: $color-white; }

	.icon {
		width: 8px;
		height: 133px;
		fill: $color-white;
		animation: arrow-down 1.5s infinite linear; }
	span {
		position: absolute;
		top: 28px;
		left: 4px;
		display: block;
		transform: rotate(90deg);
		transform-origin: left center; } }

.img-desk {
	@include md {
		display: none !important; } }

.img-desk-md {
	@include r(599) {
		display: none !important; } }
.img-md {
	@include rmin(1024) {
		display: none !important; }
	@include r(599) {
		display: none !important; } }

.img-xs {
	@include rmin(600) {
		display: none !important; } }

@keyframes arrow-down {
	0% {
		transform: translateY(10px); }
	50% {
		transform: translateY(0); }
	100% {
		transform: translateY(10px); } }
